var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"employees-overview-table",attrs:{"headers":_vm.headers,"items":_vm.filteredEmployees,"items-per-page":50,"sort-by":['lastname', 'firstname'],"loading":_vm.loading,"loading-text":_vm.$t('dataLoading'),"fixed-header":true},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.shorthand",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(item.shorthand, _vm.keyword))}})]}},{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(item.firstname, _vm.keyword))}})]}},{key:"item.lastname",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(item.lastname, _vm.keyword))}})]}},(_vm.showSupervisor)?{key:"item.supervisorName",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(item.supervisorName, _vm.keyword))}})]}}:null,(_vm.showMentor)?{key:"item.mentorName",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(item.mentorName, _vm.keyword))}})]}}:null,{key:"item.position.translation",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highlight(_vm.localize(item.position), _vm.keyword))}})]}},{key:"item.loginDelegation",fn:function(ref){
var item = ref.item;
return [(item.canBeImpersonated)?_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.delegateLogin(item)}}},[_vm._v(" Login ")]):_vm._e()]}}],null,true)}),_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"table"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }