<template>
  <div>
    <v-data-table
      v-show="!loading"
      class="employees-overview-table"
      :headers="headers"
      :items="filteredEmployees"
      :items-per-page="50"
      :sort-by="['lastname', 'firstname']"
      :loading="loading"
      :loading-text="$t('dataLoading')"
      :fixed-header="true"
      @click:row="rowClicked"
    >
      <template v-slot:item.shorthand="{ item }">
        <span
          v-html="$options.filters.highlight(item.shorthand, keyword)"
        ></span>
      </template>

      <template v-slot:item.firstname="{ item }">
        <span
          v-html="$options.filters.highlight(item.firstname, keyword)"
        ></span>
      </template>

      <template v-slot:item.lastname="{ item }">
        <span
          v-html="$options.filters.highlight(item.lastname, keyword)"
        ></span>
      </template>

      <template v-if="showSupervisor" v-slot:item.supervisorName="{ item }">
        <span
          v-html="$options.filters.highlight(item.supervisorName, keyword)"
        ></span>
      </template>

      <template v-if="showMentor" v-slot:item.mentorName="{ item }">
        <span
          v-html="$options.filters.highlight(item.mentorName, keyword)"
        ></span>
      </template>

      <template v-slot:item.position.translation="{ item }">
        <span
          v-html="$options.filters.highlight(localize(item.position), keyword)"
        ></span>
      </template>

      <template v-slot:item.loginDelegation="{ item }">
        <v-btn v-if="item.canBeImpersonated" @click.stop="delegateLogin(item)">
          Login
        </v-btn>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-show="loading"></v-skeleton-loader>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    employees: {
      type: Array,
      required: true
    },

    keyword: {
      type: String,
      required: false,
      default: ''
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    showSupervisor: {
      type: Boolean,
      required: false,
      default: false
    },

    showMentor: {
      type: Boolean,
      required: false,
      default: false
    },

    detailsRoute: {
      type: String,
      required: false,
      default: 'UserDetails'
    },

    loginDelegation: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({}),

  computed: {
    filteredEmployees() {
      if (this.loading) {
        return [];
      }

      const kw = this.keyword.toLowerCase();
      return this.employees.filter((x) => {
        if (x.shorthand.toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        if (x.firstname.toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        if (x.lastname.toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        const fullName =
          x.firstname.toLowerCase().trim() +
          ' ' +
          x.lastname.toLowerCase().trim();
        if (fullName.indexOf(kw) > -1) {
          return true;
        }

        if (this.localize(x.position).toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        if (x.supervisorName.toLowerCase().indexOf(kw) > -1) {
          return true;
        }

        return false;
      });
    },

    headers() {
      const availableHeaders = [
        {
          text: this.$t('employeeDetails.shorthand'),
          sortable: true,
          value: 'shorthand',
          show: true,
          width: '140px'
        },
        {
          text: this.$t('employeeDetails.firstName'),
          sortable: true,
          value: 'firstname',
          show: true
        },
        {
          text: this.$t('employeeDetails.lastName'),
          sortable: true,
          value: 'lastname',
          show: true
        },
        {
          text: this.$t('employeeDetails.supervisor'),
          sortable: true,
          value: 'supervisorName',
          show: this.showSupervisor
        },
        {
          text: 'Mentor',
          sortable: true,
          value: 'mentorName',
          show: this.showMentor
        },
        {
          text: this.$t('employeeDetails.position'),
          sortable: true,
          value: 'position.translation',
          show: true
        },
        {
          text: '',
          sortable: false,
          value: 'loginDelegation',
          show: this.loginDelegation
        }
      ];

      return availableHeaders.filter((x) => x.show);
    }
  },

  methods: {
    ...mapActions({
      impersonateUser: 'impersonateUser'
    }),

    rowClicked(item) {
      this.$router.push({
        name: this.detailsRoute,
        params: { shorthand: item.shorthand }
      });
    },

    async delegateLogin(user) {
      await this.impersonateUser(user);

      this.$router.push({ name: 'Dashboard' });
      setTimeout(() => window.location.reload(), 500);
    }
  }
};
</script>

<style scoped lang="scss">
.employees-overview-table::v-deep tbody tr td {
  cursor: pointer;
}

@media (max-width: 600px) {
  .employees-overview-table::v-deep table {
    display: block;
  }
}
</style>
